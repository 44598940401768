.oro247app-oro-underline {
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0;
  text-underline-position: under;
  text-decoration-line: underline;
}

.oro247app-oroinfoarea-hori-layout {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.oro247app-info {
  background-color: var(--bg-color);
  border-radius: var(--border-radius-md, 8px);
  margin: 0;
  padding: 5px 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
.oro247app-info .oro247app-item {
  margin: 0 20px;
}
.oro247app-info .oro247app-item .oro247app-label {
  font-size: var(--text-tiny);
  font-weight: var(--weight-regular);
  text-align: center;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.oro247app-info .oro247app-item .oro247app-data {
  font-size: var(--text-2xl, 20px);
  font-weight: 600;
  color: var(--text-color);
  text-align: center;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}